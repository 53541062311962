import Vue from 'vue';
import { createInertiaApp, Link } from '@inertiajs/vue2';
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta';
import { ZiggyVue } from 'ziggy';
import { Ziggy } from './ziggy';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import '../css/app.css';

import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';

import axios from 'axios';
import Cookies from 'js-cookie';
import VuetifyConfirm from 'vuetify-confirm';
import Highcharts from 'highcharts';

import * as packageData from '../../package.json';
import * as Sentry from '@sentry/vue';
import snappy from 'snappyjs';

import { Buffer } from 'buffer';
globalThis.Buffer = Buffer;

const version = packageData.version;

const socket = io(import.meta.env.VITE_HUB_SOCKET_BASE_URI, {
  transports: ['websocket', 'polling'],
  forceNew: true,
  autoConnect: false,
});

Highcharts.setOptions({
  lang: {
    months: [
      'Januar',
      'Februar',
      'Mars',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Desember',
    ],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
    weekdays: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
    downloadJPEG: 'Last ned JPEG',
    downloadPDF: 'Last ned PDF',
    downloadPNG: 'Last ned PNG',
    downloadSVG: 'Last ned SVG',
    resetZoom: 'Tilbakestill zoom',
    viewFullscreen: 'Vis i fullskjerm',
    printChart: 'Skriv ut',
  },
});

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.use(VueSocketIOExt, socket);
Vue.use(VueMeta);

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Bekreft',
  buttonFalseText: 'Avbryt',
  buttonTrueColor: 'secondary',
  buttonFalseColor: 'error',
  color: 'error',
  icon: 'mdi-exclamation-thick',
  title: 'Forsiktig!',
  property: '$confirm',
});

Vue.component('InertiaLink', Link);

Vue.use(ZiggyVue, Ziggy);

Sentry.init({
  Vue,
  dsn: import.meta.env.VITE_SENTRY_VUE,
  release: version,
  logErrors: true,
  autoSessionTracking: true,
  dist: 'production',
});

createInertiaApp({
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    Vue.use(plugin);
    new Vue({
      vuetify,
      data() {
        return {
          connectionAttempts: 0,
        };
      },
      sockets: {
        connect() {
          this.$socket.authenticated = false;
          console.log('connecting');
          this.authenticateSocketConnection();
        },
        // reconnect with polling if websockets failed
        reconnect_attempt() {
          this.$socket.client.transports = ['polling', 'websockets'];
        },
        disconnect(reason) {
          if (reason === 'io server disconnect' && this.connectionAttempts < 10) {
            this.connectionAttempts++;
            this.$socket.client.open();
          }
        },
        authenticated() {
          console.log('connected to sockets');
          this.$socket.authenticated = true;
        },
        connect_error(error) {
          console.log(error);
        },
        unauthorized(error) {
          console.log(error);
        },
        // this event is from socketio-auth package
        disconnected() {
          this.authenticateSocketConnection();
        },
        notification() {
          setTimeout(() => {
            this.$http
              .get(this.route('users.notifications.fetch'))
              .then((response) => {
                this.$set(this.$page.props.auth.user, 'notifications', response.data.data);
              })
              .catch(() => {
                // do nothing
              });
          }, 2000);
        },
      },
      methods: {
        authenticateSocketConnection() {
          console.log('authenticating');
          const payload = snappy.compress(
            Buffer.from(
              JSON.stringify({
                type: 'client',
                id: Cookies.get('XSRF-TOKEN'),
              }),
              'utf-8',
            ),
          );
          this.$socket.client.emit('authentication', payload);
        },
      },
      metaInfo: {
        titleTemplate: (title) => (title ? `${title} | Evolo` : 'Evolo'),
      },
      render: (h) => h(App, props),
    }).$mount(el);
    socket.connect();
  },
});
