import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// Scroll Directive
import { Scroll, Touch, Intersect } from 'vuetify/lib/directives';

Vue.use(Vuetify, {
  directives: {
    Scroll,
    Touch,
    Intersect,
  },
});

// Material Design Icons
import '@mdi/font/css/materialdesignicons.css';

// Norwegian translation
import no from 'vuetify/es5/locale/no.js';

// Google Roboto Fonts
import 'typeface-roboto/index.css';

export default new Vuetify({
  lang: {
    current: 'no',
    locales: { no },
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#31708E',
        secondary: '#20495C',
        accent: '#6990a2',
        lightblue: '#4daedb',
        whitegray: '#f8f9fa',
      },
    },
  },
});
